import React from "react";

const Footer = () => {
    return (
        <div className='footerSection'>
            <div className='container'>
                <div className='footerWrapper'>
                    <div>
                        <a href='/'><img src='/imgs/navLogo.svg' alt='Defense Therapy Logo'></img></a>
                    </div>
                    <div className='footerContentDivs'>
                        <h4 className='footerSubHeading'> Quick Links </h4>
                        <a className='footerLink' href='/'>Home</a>
                        <a className='footerLink' href='https://squareup.com/appointments/buyer/widget/goglug4njcvntf/LMHW1P0587EWV' target="_blank" rel="noopener noreferrer">Schedule</a>
                        <a className='footerLink' href='/classes'>Classes</a>
                        <a className='footerLink' href='/blog'>Blog</a>
                    </div>
                    <div className='footerContentDivs'>
                        <h4 className='footerSubHeading'>About Defense Therapy</h4>
                        <a className='footerLink' href='/about'>About Us</a>
                    </div>
                    <div className='footerContentDivs'>
                        <h4 className='footerSubHeading'>Follow Us</h4>
                        <div className='followUsLinks'>
                            <a href='https://www.instagram.com/coach_k03' target='_blank' rel='noopener noreferrer'><img className='followUsImg'  src='/imgs/Instagram.svg' alt='Defense Therapy Instagram'></img></a>
                            <a href='https://www.tiktok.com/@coachkguntraining' target='_blank' rel='noopener noreferrer'><img className='followUsImg' src='/imgs/TikTok.svg' alt='Defense Therapy TikTok'></img></a>
                        </div>
                    </div>
                </div>
                <div className='copyRightDiv'><p className='copyRightText'>2025 © Defense Therapy | by <a className='copyRightText' href='https://www.codenamekd.dev/'target='_blank' rel='noopener noreferrer'>CodeNameKD</a></p></div>
            </div>
        </div>
    )
}
export default Footer;